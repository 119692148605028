<template>
  <v-container fluid class="pa-0">
    <v-window v-model="window" touchless>

      <!-- Choose phone or email -->
      <v-window-item :value="0">
        <v-row dense>
          <v-col cols="12" class="title">
            Authentication
          </v-col>
          <v-col cols="12">
            <p>To protect your security, we need to confirm it is really you.</p>
            <p>Please choose how you would like to receive your temporary identification code. Listed below are the phone numbers and email address we have on your file.</p>
            <p>If you don't recognize the contact information listed below, please contact us at <a href="mailto:2fsupport@volleyballlife.com">2fsupport@volleyballlife.com</a></p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-radio-group v-model="selectedChallenge">
              <v-radio v-for="(challenge, i) in challenges" :value="challenge" :key="i" color="success">
                <template v-slot:label>
                  <div>
                    <v-icon>fas fa-{{challenge.contactType === 'email' ? 'envelope' : 'mobile-alt'}} fa-fw</v-icon>
                    {{challenge.contactType === 'email' ? 'Email: ' : 'Text Messgae: '}}{{challenge.maskedContact}}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              color="success white--text"
              :disabled="!selectedChallenge"
              :loading="loading"
              @click.stop="sendCode"
            >Send Code</v-btn>
          </v-col>
        </v-row>
      </v-window-item>

      <!-- Enter verficiation code -->
      <v-window-item :value="1">
        <v-row dense>
          <v-col cols="12">
            <div class="title">Enter the verficiation code</div>
            <div v-if="!skip">We just sent {{selectedChallenge && selectedChallenge.contactType === 'email' ? 'an email' : 'a text message'}} with a fresh verification code</div>
            <div class="caption">Note: only the last code issued will be valid and codes are only good for 10 minutes</div>
            <v-text-field
              label="Verification Code"
              v-model="vCode"
              color="color3"
              type="number"
            ></v-text-field>
            <v-alert type="error" :value="!!codeError" text prominent>
              {{codeError}}
            </v-alert>
            <div class="text-center pb-3">
              <v-btn
                color="color3 color3Text--text"
                :disabled="!validCode"
                @click.stop="verifyCode"
                :loading="loading"
              >Go</v-btn>
            </div>
            <div class="caption text-center">Didn't receive a code? <span class="clickable color3--text" @click.stop="reset">Try again.</span></div>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <v-row dense>
      <v-col cols="12" :class="window === 0 ? 'text-center' : null">
        <v-fab-transition>
          <v-btn
            v-if="window > 0"
            color="color3Text color3--text"
            small fab
            @click.stop="reset"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="color3"
            class="mt-5"
            text x-small
            @click.stop="skipToCode"
            v-if="window === 0"
          >I already have a code</v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['challenges'],
  data () {
    return {
      window: 0,
      selectedChallenge: null,
      loading: false,
      vCode: null,
      codeError: null,
      skip: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    validCode () {
      return this.vCode && this.vCode.length === 6
    }
  },
  methods: {
    reset () {
      this.window = 0
      this.vCode = null
      this.codeError = null
      this.skip = false
    },
    skipToCode () {
      this.skip = true
      this.window = 1
    },
    sendCode () {
      if (this.selectedChallenge) {
        this.loading = true
        this.$VBL.user.twoFactor(this.selectedChallenge)
          .then(r => {
            this.window = 1
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      }
    },
    verifyCode () {
      if (this.validCode) {
        this.loading = true
        this.$VBL.user.contact.confirm(this.vCode)
          .then(r => {
            this.$emit('passed')
          })
          .catch(e => {
            console.log(e)
            this.codeError = e.response.data.Message
          })
          .finally(() => { this.loading = false })
      }
    }
  },
  watch: {
    challenges: 'reset'
  },
  mounted () {
    this.reset()
  }
}
</script>
<style scoped>
  h2.lined {
    border-bottom: 1px solid grey;
    line-height: 0.1em;
  }
  h2.lined span {
    padding: 0 10px;
    background: #fff
  }
</style>
